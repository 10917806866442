import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getBreefSetting = async () => {
  const token = localStorage.getItem("BreefAccesstoken");
  
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/breef-setting`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      localStorage.removeItem("access_token");
      // navigate("/");
    }
    console.log("error ", error);
  }
};
